<template>
    <div id="logging-out" class="centered-message">
        <h2 class="text-primary">
            {{ t('loggingOut') }}
        </h2>
    </div>
</template>
<script>
export default {
    name: 'LoggingOutPage',
    mounted() {
        this.setSpinner(true);
        this.logout();
    },
};
</script>
<style scoped>
#logging-out {
    animation: pulse 600ms ease-in-out 0s infinite;
}

@keyframes pulse {
    0% {
        height: 70px;
    }

    50% {
        height: 90px;
    }

    100% {
        height: 70px;
    }
}
</style>
